import React, { useState, useEffect } from 'react';
import MatrixInput from './MatrixInput';
import './LUDecomposition.css';

function LUDecomposition() {
  const [size, setSize] = useState(2);
  const [matrix, setMatrix] = useState(() => Array(size).fill().map(() => Array(size).fill('')));
  const [resultL, setResultL] = useState([]);
  const [resultU, setResultU] = useState([]);

  useEffect(() => {
    setMatrix(prevMatrix => {
      const newMatrix = Array(size).fill().map(() => Array(size).fill(''));
      for (let i = 0; i < Math.min(prevMatrix.length, size); i++) {
        for (let j = 0; j < Math.min(prevMatrix[i].length, size); j++) {
          newMatrix[i][j] = prevMatrix[i][j];
        }
      }
      return newMatrix;
    });
  }, [size]);

  // const resizeMatrix = (matrix, size) => {
  //   // Function implementation
  // };

  const handleMatrixChange = (e, i, j) => {
    const value = e.target.value;
    setMatrix(prevMatrix => {
      const newMatrix = prevMatrix.map(row => [...row]);
      newMatrix[i][j] = value;
      return newMatrix;
    });
  };

  const handleSizeChange = (e) => {
    const value = e.target.value;
    if (value === '' || /^\d+$/.test(value)) {
      const numValue = value === '' ? 2 : Math.min(10, Math.max(2, parseInt(value)));
      setSize(numValue);
    }
  };

  const calculateLU = () => {
    const n = size;
    const A = matrix.map(row => row.map(val => parseFloat(val) || 0));
    const L = Array(n).fill().map(() => Array(n).fill(0));
    const U = Array(n).fill().map(() => Array(n).fill(0));

    for (let i = 0; i < n; i++) {
      L[i][i] = 1;

      for (let j = i; j < n; j++) {
        let sum = 0;
        for (let k = 0; k < i; k++) {
          sum += L[i][k] * U[k][j];
        }
        U[i][j] = A[i][j] - sum;
      }

      for (let j = i + 1; j < n; j++) {
        let sum = 0;
        for (let k = 0; k < i; k++) {
          sum += L[j][k] * U[k][i];
        }
        L[j][i] = (A[j][i] - sum) / U[i][i];
      }
    }

    // Round the results to 3 decimal places
    const roundMatrix = (matrix) => matrix.map(row => row.map(val => Number(val.toFixed(3))));
    setResultL(roundMatrix(L));
    setResultU(roundMatrix(U));
  };

  const ResultMatrix = ({ matrix }) => (
    <div className="matrix-wrapper">
      {matrix.map((row, i) => (
        <div key={i}>
          {row.map((value, j) => (
            <input
              key={j}
              type="text"
              value={typeof value === 'number' ? value.toFixed(3) : value}
              readOnly
              className="matrix-cell result-input"
            />
          ))}
        </div>
      ))}
    </div>
  );

  return (
    <div className="lu-decomposition">
      <h2>LU Decomposition Calculator</h2>
      <div className="matrix-container">
        <div className="matrix-wrapper">
          <MatrixInput
            rows={size}
            cols={size}
            matrix={matrix}
            onChange={handleMatrixChange}
          />
        </div>
      </div>
      <div className="size-controls">
        <label>Size:</label>
        <input
          type="text"
          value={size}
          onChange={handleSizeChange}
          className="size-input"
        />
      </div>
      <button onClick={calculateLU}>Calculate LU Decomposition</button>
      {resultL.length > 0 && resultU.length > 0 && (
        <div className="result-container">
          <h2>Result</h2>
          <div className="matrix-container">
            <div className="matrix-wrapper">
              <h3>L Matrix</h3>
              <ResultMatrix matrix={resultL} />
            </div>
            <div className="matrix-wrapper">
              <h3>U Matrix</h3>
              <ResultMatrix matrix={resultU} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default LUDecomposition;
