import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import MatrixInput from './MatrixInput';
import './QRFactorization.css';

function QRFactorization() {
  const [size, setSize] = useState(2);
  const [matrix, setMatrix] = useState(() => Array(size).fill().map(() => Array(size).fill('')));
  const [resultQ, setResultQ] = useState([]);
  const [resultR, setResultR] = useState([]);

  useEffect(() => {
    setMatrix(prevMatrix => {
      const newMatrix = Array(size).fill().map(() => Array(size).fill(''));
      for (let i = 0; i < Math.min(prevMatrix.length, size); i++) {
        for (let j = 0; j < Math.min(prevMatrix[i].length, size); j++) {
          newMatrix[i][j] = prevMatrix[i][j];
        }
      }
      return newMatrix;
    });
  }, [size]);

  const handleMatrixChange = (e, i, j) => {
    const value = e.target.value;
    setMatrix(prevMatrix => {
      const newMatrix = prevMatrix.map(row => [...row]);
      newMatrix[i][j] = value;
      return newMatrix;
    });
  };

  const handleSizeChange = (e) => {
    const value = e.target.value;
    if (value === '' || /^\d+$/.test(value)) {
      const numValue = value === '' ? 2 : Math.min(10, Math.max(2, parseInt(value)));
      setSize(numValue);
    }
  };

  const calculateQR = () => {
    const A = matrix.map(row => row.map(val => parseFloat(val) || 0));
    const m = A.length;
    const n = A[0].length;

    // Initialize Q and R
    const Q = Array(m).fill().map(() => Array(n).fill(0));
    const R = Array(n).fill().map(() => Array(n).fill(0));

    // Gram-Schmidt process
    for (let j = 0; j < n; j++) {
      let v = A.map(row => row[j]);

      for (let i = 0; i < j; i++) {
        R[i][j] = Q.map((row, k) => row[i] * A[k][j]).reduce((sum, val) => sum + val, 0);
        v = v.map((val, k) => val - R[i][j] * Q[k][i]);
      }

      R[j][j] = Math.sqrt(v.reduce((sum, val) => sum + val * val, 0));
      for (let k = 0; k < m; k++) {
        Q[k][j] = v[k] / R[j][j];
      }
    }

    // Round the results to 3 decimal places
    const roundMatrix = (matrix) => matrix.map(row => row.map(val => Number(val.toFixed(3))));
    setResultQ(roundMatrix(Q));
    setResultR(roundMatrix(R));
  };

  const ResultMatrix = ({ matrix }) => (
    <div className="matrix-wrapper">
      {matrix.map((row, i) => (
        <div key={i}>
          {row.map((value, j) => (
            <input
              key={j}
              type="text"
              value={typeof value === 'number' ? value.toFixed(3) : value}
              readOnly
              className="matrix-cell result-input"
            />
          ))}
        </div>
      ))}
    </div>
  );

  return (
    <div className="qr-factorization">
      <Helmet>
        <title>QR Factorization Calculator | MathSucks.lol</title>
        <meta name="description" content="Perform QR factorization on matrices with our free online calculator. Get accurate Q and R matrices for your linear algebra problems." />
      </Helmet>
      <h2>QR Factorization Calculator</h2>
      <div className="matrix-container">
        <div className="matrix-wrapper">
          <MatrixInput
            rows={size}
            cols={size}
            matrix={matrix}
            onChange={handleMatrixChange}
          />
        </div>
      </div>
      <div className="size-controls">
        <label>Size:</label>
        <input
          type="text"
          value={size}
          onChange={handleSizeChange}
          className="size-input"
        />
      </div>
      <button onClick={calculateQR}>Calculate QR Factorization</button>
      {resultQ.length > 0 && resultR.length > 0 && (
        <div className="result-container">
          <h2>Result</h2>
          <div className="matrix-container">
            <div className="matrix-wrapper">
              <h3>Q Matrix</h3>
              <ResultMatrix matrix={resultQ} />
            </div>
            <div className="matrix-wrapper">
              <h3>R Matrix</h3>
              <ResultMatrix matrix={resultR} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default QRFactorization;
